import React, {useState} from 'react'
import Logo from '../../assets/logo.png'
import Bars from '../../assets/bars.png'
import { Link } from 'react-scroll'
import './Header.css'

const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false
  const [menuOpened, setMenueOpened] = useState(false)
  return (
    <div className='header'>
      <img src={Logo} alt='' className='logo' />
      {menuOpened === false && mobile === true ? (
        <div style={{
          backgroundColor: 'var(--appColor)', 
          padding: '0.5rem', 
          borderRadius: '5px'
        }} 
        onClick={() => setMenueOpened(true)}
        >
          <img src={Bars} alt='' style={{width: '1.5rem', height: '1.5rem'}} />
        </div>
      ): (<ul className='header-manue'>
          <li>
          <Link onClick={() => setMenueOpened(false)}
          to='home'
          activeClass='active'
          span={true}
          smooth={true}
          >Home</Link>
            </li>
          <li>
            <Link onClick={() => setMenueOpened(false)}
            to='programs'
            span={true}
            smooth={true}
            >Programs</Link>
            </li>
          <li >
              <Link onClick={() => setMenueOpened(false)}
              to='reasons'
              span={true}
              smooth={true}
              >
                Why us
              </Link>
            </li>
          <li>
            <Link onClick={() => setMenueOpened(false)}
            to='plans'
            span={true}
            smooth={true}
            >
            Plans
            </Link>
            </li>
          <li>
            <Link 
            to='testimonials'
            span={true}
            smooth={true}
            onClick={() => setMenueOpened(false)}
            >Testmonial</Link>
            </li>
        </ul>
        )}
    </div>
  )
}

export default Header